import { AppointmentWaitlistFormContext, ViewModelFactory } from '../../types';
import { WAITLIST_FORM_ID } from '../../constants';
import type { FormOverride } from '@wix/form-viewer/dist/types/services/form-overrides';
import {
  WaitlistSubmission,
  WaitlistSubmissionKey,
} from '@wix/appointment-waitlist-common';

export type FormViewModel = {
  formId: string;
  initialValues: Partial<WaitlistSubmission>;
  fieldsOverride: Partial<Record<WaitlistSubmissionKey, FormOverride>>;
};

export const createFormViewModel: ViewModelFactory<FormViewModel> = ({
  context,
}) => {
  const initialValues = getInitialValues({ context });

  return {
    formId: WAITLIST_FORM_ID,
    initialValues,
    fieldsOverride: getFieldsOverrides({ initialValues }),
  };
};

const getFieldsOverrides = ({
  initialValues,
}: {
  initialValues: FormViewModel['initialValues'];
}) => {
  const fields: WaitlistSubmissionKey[] = [
    'first_name',
    'last_name',
    'phone',
    'email',
  ];

  return fields.reduce<FormViewModel['fieldsOverride']>((acc, field) => {
    acc[field] = {
      disabled: !!initialValues[field],
      required: true,
    };
    return acc;
  }, {});
};

const getInitialValues = ({
  context: { availabilityPreferences, member },
}: {
  context: AppointmentWaitlistFormContext;
}): Partial<WaitlistSubmission> => {
  const { locations, services, fromLocaleDate } = availabilityPreferences;

  const getServiceDataByIndex = (index: number) => {
    const serviceKeys: WaitlistSubmissionKey[] = [
      'service1',
      'service2',
      'service3',
      'service4',
      'service5',
    ];
    const staffKeys: WaitlistSubmissionKey[] = [
      'staff1',
      'staff2',
      'staff3',
      'staff4',
      'staff5',
    ];

    if (!services[index]) {
      return {};
    }

    return {
      [serviceKeys[index]]: services[index].serviceId,
      [staffKeys[index]]: services[index].resourceTypes[0].resourceIds,
    };
  };

  const getMemberDetails = (): Pick<
    WaitlistSubmission,
    'first_name' | 'last_name' | 'email' | 'phone'
  > => {
    if (!member) {
      return {};
    }

    const { contact, loginEmail } = member;
    const { firstName, lastName, phones } = contact || {};

    return {
      first_name: firstName,
      last_name: lastName,
      phone: phones?.[0],
      email: loginEmail,
    };
  };

  return {
    time_of_day: ['Morning', 'Afternoon', 'Evening'],
    location: locations.map((location) => location.id || location.locationType),
    selected_date: fromLocaleDate.split('T')[0],
    ...getMemberDetails(),
    ...getServiceDataByIndex(0),
    ...getServiceDataByIndex(1),
    ...getServiceDataByIndex(2),
    ...getServiceDataByIndex(3),
    ...getServiceDataByIndex(4),
  };
};
