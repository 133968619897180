import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import {
  getServerBaseUrl,
  getInstance,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { queryServices } from '@wix/ambassador-bookings-services-v2-service/http';

export const MEMBERS_SERVER_API = '_api/members/v1/members';

export class AppointmentWaitlistFormAPI {
  private membersServer: ReturnType<typeof MembersNgApi>;

  constructor(private flowAPI: ControllerFlowAPI) {
    const serverBaseUrl = getServerBaseUrl({
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
      appParams: flowAPI.controllerConfig.appParams,
    });

    this.membersServer = MembersNgApi(`${serverBaseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
  }

  private get authorization() {
    return getInstance({
      wixCodeApi: this.flowAPI.controllerConfig.wixCodeApi,
      appParams: this.flowAPI.controllerConfig.appParams,
    });
  }

  async getMemberDetails({ id }: { id: string }) {
    const membersService = this.membersServer.Members();

    const { member } = await membersService({
      Authorization: this.authorization,
    }).getMember({
      fieldSet: 'FULL',
      id,
    });

    return member;
  }

  async queryServicesByIds({ ids }: { ids: string[] }) {
    return this.flowAPI.httpClient.request(
      queryServices({
        query: {
          filter: {
            id: {
              $in: ids,
            },
          },
        },
      }),
    );
  }
}
